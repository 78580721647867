import _toStringTagSupport from "../internals/to-string-tag-support";
import _redefine from "../internals/redefine";
import _objectToString from "../internals/object-to-string";
var TO_STRING_TAG_SUPPORT = _toStringTagSupport;
var redefine = _redefine;
var toString = _objectToString; // `Object.prototype.toString` method
// https://tc39.es/ecma262/#sec-object.prototype.tostring

if (!TO_STRING_TAG_SUPPORT) {
  redefine(Object.prototype, "toString", toString, {
    unsafe: true
  });
}

export default {};